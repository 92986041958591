import {readItem, updateItem} from '@directus/sdk'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {APP_HOST} from '../../directus/constants'
import {useDirectus} from '../../directus/DirectusContext'
import Question from '../../directus/types/schema/Question'
import QuestionFormDataType, {defaultQuestionFormData} from '../../types/QuestionFormDataType'
import RelatedQuestion from './RelatedQuestion'

const statusToNameMap = {
	[`new`]: 'Nový dotaz (čeká na zpracování LLM)',
	[`pending`]: 'Dotaz ke zpracování',
	[`accepted`]: 'Dotaz přijatý veterinářem',
	[`answered`]: 'Zodpovězený dotaz',
	[`archived`]: 'Archivovaný dotaz',
}

type QuestionDetailPropsType = {
	close: () => void
	questions: Question[]
	questionId: string
	// selectedQuestion: Question
	// disabled?: boolean
	// update: () => void
	// save: (formData: QuestionFormDataType, close?: boolean) => Promise<void>
}

const QuestionDetail = (
	{
		close,
		questions,
		questionId,
	}: QuestionDetailPropsType,
) => {
	const {client, user} = useDirectus()

	const [version, setVersion] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)
	const [questionData, setQuestionData] = useState<null | Question>(null)
	const [formData, setFormData] = useState<QuestionFormDataType>(defaultQuestionFormData)
	const [localChanges, setLocalChanges] = useState<boolean>(false)
	const [skippedExternalChanges, setSkippedExternalChanges] = useState<null | string>(null)

	const question = useMemo(() => {
		return questions.find(question => question.id === questionId) ?? null
	}, [questions, questionId])

	const externalChanges = useMemo(() => {
		return question?.updated_date !== skippedExternalChanges &&
			moment(question?.updated_date).isAfter(moment(questionData?.updated_date))
	}, [questionData, question, skippedExternalChanges])

	const isDisabled = externalChanges ||
		questionData?.status !== 'accepted' ||
		questionData.veterinarian_user?.id !== user?.id

	const handleSave = useCallback(async (doClose: boolean = false, additionalData: Partial<Question> = {}) => {
		if (externalChanges) return

		await client.request(updateItem(
			// @ts-ignore
			'questions',
			questionId,
			{
				name: formData.name ?? questionData?.name,
				answer: formData.answer ?? questionData?.answer,
				veterinarian_evaluation: formData.veterinarian_evaluation ?? questionData?.veterinarian_evaluation,
				veterinarian_evaluation_reason: formData.veterinarian_evaluation_reason ?? questionData?.veterinarian_evaluation_reason,
				...additionalData,
			},
			{fields: ['id']},
		))

		setFormData(defaultQuestionFormData)
		setLocalChanges(false)
		setVersion(prevState => ++prevState)

		if (doClose) {
			close()
		}
	}, [client, close, externalChanges, formData.answer, formData.name, formData.veterinarian_evaluation, formData.veterinarian_evaluation_reason, questionData?.answer, questionData?.name, questionData?.veterinarian_evaluation, questionData?.veterinarian_evaluation_reason, questionId])

	const handleAccept = useCallback(async () => {
		if (externalChanges || user === null) {
			return
		}//

		// eslint-disable-next-line no-restricted-globals
		if (!confirm('Opravdu si přejete přijmout dotaz?')) {
			return
		}

		await client.request(updateItem(
			// @ts-ignore
			'questions',
			questionId,
			{
				status: 'accepted',
				veterinarian_user: user.id,
				veterinarian_accepted_date: moment().toISOString(),
			},
			{fields: ['id']},
		))

		setVersion(prevState => ++prevState)
	}, [externalChanges, user, client, questionId])

	const handleAnswer = useCallback(async () => {
		if (isDisabled || user === null) {
			return
		}

		await handleSave()

		await client.request(updateItem(
			// @ts-ignore
			'questions',
			questionId,
			{
				status: 'answered',
				veterinarian_user: user.id,
				veterinarian_answered_date: moment().toISOString(),
			},
			{fields: ['id']},
		))

		await fetch(`${APP_HOST}/hook/question-answered`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				question: questionId,
			}),
		})

		setVersion(prevState => ++prevState)

		close()
	}, [questionId, client, user, isDisabled, close, handleSave])

	useEffect(() => {
		(async () => {
			setLoading(true)
			setLocalChanges(false)

			setQuestionData(
				// @ts-ignore
				await client.request(
					readItem(
						// @ts-ignore
						'questions',
						questionId,
						{
							fields: [
								'id',
								'aid',
								'status',
								'created_date',
								'updated_date',
								'name',
								'question',
								'answer',
								'gpt_answer',
								'veterinarian_accepted_date',
								'veterinarian_answered_date',
								'veterinarian_evaluation',
								'veterinarian_evaluation_reason',
								'issue_start_date',
								'initial_symptoms',
								'amount_of_liquids',
								'amount_of_food',
								'medication',
								{
									created_user: [
										'id',
										'aid',
										'email',
										'first_name',
										'last_name',
										{
											avatar: [
												'id',
											],
										},
									],
									animal: [
										'id',
										'name',
										'birth',
										'weight',
										'microchipped',
										'chip_number',
										'neutered',
										'passport_number',
										{
											avatar: [
												'id',
											],
											breed: [
												'id',
												'name',
											],
											species: [
												'id',
												'name',
											],
											environment: [
												'id',
												'name',
											],
											food: [
												'id',
												'name',
											],
											gender: [
												'id',
												'name',
											],
											questions: [
												'id',
												'aid',
												'created_date',
												'name',
												'question',
												'answer',
											],
										},
									],
									veterinarian_user: [
										'id',
										'email',
										'first_name',
										'last_name',
										{
											avatar: [
												'id',
											],
										},
									],
								},
							],
						},
					),
				) as Question,
			)

			setLoading(false)
		})().then().catch()
	}, [client, questionId, version])

	if (loading || questionData === null) {
		return (
			<div>
				loading...
			</div>
		)
	}

	return (
		<div className="flex flex-col lg:flex-row gap-4 h-full">
			<div className="lg:sticky lg:top-0 w-full lg:w-1/2 flex-shrink-0 flex flex-col gap-4">
				<div className="flex flex-row justify-between align-middle pb-4 border-b-[1px] border-b-gray-200">
					<h1 className="font-bold text-4xl">
						<small className="text-gray-500">Dotaz:</small>{' '}
						#{questionData.created_user.aid}-{questionData.aid}
						{localChanges && (
							<>
								{' '}<strong className="text-danger">*</strong>
							</>
						)}
					</h1>
					<div className="flex flex-row gap-4">
						{externalChanges && (
							<button
								type="button"
								className="bg-white border border-danger rounded-md p-2 text-gray-500"
								onClick={() => {
									setSkippedExternalChanges(question?.updated_date ?? null)
								}}
							>
								Ignorovat externí změny
							</button>
						)}
						<button
							type="button"
							className="bg-white border border-gray-300 rounded-md p-2 text-gray-500"
							onClick={() => {
								if (!localChanges) {
									setVersion(prevState => ++prevState)

									return
								}

								// eslint-disable-next-line no-restricted-globals
								if (!confirm('Opravdu si přejete pokračovat?')) {
									return
								}

								setFormData(defaultQuestionFormData)
								setVersion(prevState => ++prevState)
							}}
						>
							Načíst znovu
						</button>
						<button
							type="button"
							className="bg-danger border border-gray-300 rounded-md p-2 text-white"
							onClick={() => {
								if (!localChanges) {
									close()

									return
								}

								// eslint-disable-next-line no-restricted-globals
								if (!confirm('Opravdu si přejete pokračovat?')) {
									return
								}

								setFormData(defaultQuestionFormData)
								close()
							}}
						>
							Zavřít
						</button>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<div className="flex items-center space-x-4">
					<span className="text-sm font-medium text-gray-700 w-32">
						Otázka:
					</span>
						<p
							className="p-2 bg-gray-50 border border-gray-300 rounded-md flex-1"
						>
							{questionData.question}
						</p>
					</div>
					<div className="flex items-center space-x-4">
						<label htmlFor="name" className="text-sm font-medium text-gray-700 w-32">
							Pojmenování:
						</label>
						<input
							id="name"
							type="text"
							value={formData.name ?? questionData.name ?? ''}
							onChange={event => {
								setLocalChanges(true)

								setFormData(prevValue => ({
									...prevValue,
									name: event.target.value,
								}))
							}}
							disabled={isDisabled}
							className="p-2 border border-gray-300 rounded-md disabled:bg-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 flex-1"
						/>
					</div>
					<div className="flex items-center space-x-4">
						<label htmlFor="answer" className="text-sm font-medium text-gray-700 w-32">
							Odpověď:
						</label>
						<textarea
							id="answer"
							value={formData.answer ?? questionData.answer ?? ''}
							onChange={event => {
								setLocalChanges(true)

								setFormData(prevValue => ({
									...prevValue,
									answer: event.target.value,
								}))
							}}
							disabled={isDisabled}
							rows={10}
							className="p-2 border border-gray-300 rounded-md disabled:bg-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 flex-1"
						/>
					</div>
					<div className="flex items-center space-x-4">
						<span className="text-sm font-medium text-gray-700 w-32">
							Hodnocení LLM:
						</span>
						<ul className="flex flex-row flex-grow justify-between">
							{Array.from({length: 10}, (_, i) => i + 1).map((evaluationValue, index) => (
								<li
									key={index}
								>
									<button
										type="button"
										onClick={() => {
											if (isDisabled) return

											setLocalChanges(true)

											setFormData(prevValue => ({
												...prevValue,
												veterinarian_evaluation: evaluationValue,
											}))
										}}
										className={`w-8 p-2 border-[1px] border-gray-300 rounded text-center text-sm ${evaluationValue === (formData.veterinarian_evaluation ?? questionData.veterinarian_evaluation) ? (isDisabled ? 'bg-primary-200 text-white' : 'bg-primary-500 text-white') : (isDisabled ? 'bg-gray-200 text-black' : 'bg-white text-black')} ${isDisabled ? 'cursor-default' : 'cursor-pointer'}`}
									>
										{evaluationValue}
									</button>
								</li>
							))}
						</ul>
					</div>
					<div className="flex items-center space-x-4">
						<label htmlFor="veterinarian_evaluation_reason" className="text-sm font-medium text-gray-700 w-32">
							Důvod hodnocení:
						</label>
						<textarea
							id="veterinarian_evaluation_reason"
							value={formData.veterinarian_evaluation_reason ?? questionData.veterinarian_evaluation_reason ?? ''}
							onChange={event => {
								setLocalChanges(true)

								setFormData(prevValue => ({
									...prevValue,
									veterinarian_evaluation_reason: event.target.value,
								}))
							}}
							disabled={isDisabled}
							rows={4}
							className="p-2 border border-gray-300 rounded-md disabled:bg-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 flex-1"
						/>
					</div>
					<div className="flex flex-row gap-4 mt-4 justify-center">
						{questionData.status === 'pending' && (
							<button
								type="button"
								onClick={() => handleAccept()}
								disabled={externalChanges}
								className="bg-primary-500 border border-gray-300 rounded-md p-2 text-white"
							>
								Přijmout dotaz
							</button>
						)}
						{questionData.status === 'accepted' && questionData.veterinarian_user?.id === user?.id && (
							<button
								type="button"
								onClick={() => handleAnswer()}
								disabled={isDisabled}
								className="bg-primary-500 border border-gray-300 rounded-md p-2 text-white"
							>
								Uložit a zodpovědět dotaz
							</button>
						)}
						{!isDisabled && (
							<>
								<button
									type="button"
									onClick={() => handleSave()}
									disabled={isDisabled}
									className="bg-white border border-gray-300 rounded-md p-2 text-black"
								>
									Uložit
								</button>
								<button
									type="button"
									onClick={() => handleSave(true)}
									disabled={isDisabled}
									className="bg-white border border-gray-300 rounded-md p-2 text-black"
								>
									Uložit a zavřít
								</button>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col flex-grow gap-4">
				<div className="pl-4 border-gray-300 border-l-[1px]">
					<h3 className="font-bold text-lg">Informace</h3>
					<div className="flex flex-row flex-wrap">
						<div className="w-1/2">
							<span>Status</span><br/>
							<small>{statusToNameMap[questionData.status] ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Datum položení</span><br/>
							<small>{`${moment(questionData.created_date).format('D.M.YYYY HH:mm')} - ${moment(questionData.created_date).fromNow()}`}</small>
						</div>
						<div className="w-1/2">
							<span>Veterinář</span><br/>
							<small>
								{questionData.veterinarian_user?.first_name}{' '}
								{questionData.veterinarian_user?.last_name}{' '}
								({questionData.veterinarian_user?.email})
							</small>
						</div>
						<div className="w-1/2">
							<span>Datum přijetí</span><br/>
							<small>{questionData.veterinarian_accepted_date ? `${moment(questionData.veterinarian_accepted_date).format('D.M.YYYY FF:mm')} - ${moment(questionData.veterinarian_accepted_date).fromNow()}` : '-'}</small>
						</div>
						{/*<div className="w-1/2">*/}
						{/*	<span>Datum zodpovězení</span><br/>*/}
						{/*	<small>{questionData.veterinarian_answered_date ? `${moment(questionData.veterinarian_answered_date).format('D.M.YYYY FF:mm')} - ${moment(questionData.veterinarian_answered_date).fromNow()}` : '-'}</small>*/}
						{/*</div>*/}
						{!externalChanges && user && questionData.veterinarian_user && user.id !== questionData.veterinarian_user.id && (
							<div className="w-1/2">
								<span>Převzít dotaz</span><br/>
								<small>
									<button
										type="button"
										className="hover:underline"
										onClick={async () => {
											await client.request(updateItem(
												// @ts-ignore
												'questions',
												questionId,
												{
													veterinarian_user: user.id,
												}
											))
										}}
									>
										Nastavit mě jako veterináře
									</button>
								</small>
							</div>
						)}
					</div>
				</div>
				<div className="pl-4 border-gray-300 border-l-[1px]">
					<h3 className="font-bold text-lg">Zvíře</h3>
					<div className="flex flex-row flex-wrap">
						<div className="w-1/2">
							<span>Jméno</span><br/>
							<small>{questionData.animal.name}</small>
						</div>
						<div className="w-1/2">
							<span>Druh zvířete</span><br/>
							<small>{questionData.animal.species?.name ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Plemeno</span><br/>
							<small>{questionData.animal.breed?.name ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Datum narození</span><br/>
							<small>{questionData.animal.birth ? moment(questionData.animal.birth).format('DD.MM.YYYY') : '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Pohlaví</span><br/>
							<small>{questionData.animal.gender?.name ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Váha</span><br/>
							<small>{questionData.animal.weight ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Strava</span><br/>
							<small>{questionData.animal.food?.name ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Kastrovaný</span><br/>
							<small>{questionData.animal.neutered !== null ? (questionData.animal.neutered ? 'ano' : 'ne') : '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Čipovaný</span><br/>
							<small>{questionData.animal.microchipped !== null ? (questionData.animal.microchipped ? 'ano' : 'ne') : '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Číslo čipu</span><br/>
							<small>{questionData.animal.chip_number ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Číslo pasu</span><br/>
							<small>{questionData.animal.passport_number ?? '-'}</small>
						</div>
					</div>
				</div>
				<div className="pl-4 border-gray-300 border-l-[1px]">
					<h3 className="font-bold text-lg">Dotazník</h3>
					<div className="flex flex-row flex-wrap">
						<div className="w-full">
							<span>Kdy daný problém začal?</span><br/>
							<small>{questionData.issue_start_date ? moment(questionData.issue_start_date).format('DD.MM.YYYY') : '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Pije normálně?</span><br/>
							<small>{questionData.amount_of_liquids ?? '-'}</small>
						</div>
						<div className="w-1/2">
							<span>Žere normálně?</span><br/>
							<small>{questionData.amount_of_food ?? '-'}</small>
						</div>
						<div className="w-full">
							<span>Jaké byly prvotní příznaky?</span><br/>
							<small>{questionData.initial_symptoms ?? '-'}</small>
						</div>
						<div className="w-full">
							<span>Bere zvíře léky? Jaké?</span><br/>
							<small>{questionData.medication ?? '-'}</small>
						</div>
					</div>
				</div>
				<div className="pl-4 border-gray-300 border-l-[1px]">
					<h3 className="font-bold text-lg">Dotazy</h3>
					<div className="flex flex-col gap-2">
						{(questionData.animal.questions as Question[]).map(q => (
							<RelatedQuestion masterQuestion={questionData} question={q}/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default QuestionDetail
