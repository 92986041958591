import {createContext, useContext} from 'react'
import {DirectusContextType} from './types/DirectusContextType'

const DirectusContext = createContext<DirectusContextType | undefined>(undefined)

export default DirectusContext

export const useDirectus = (): DirectusContextType => {
	const context = useContext(DirectusContext)

	if (!context) {
		throw new Error('useDirectus must be used within a DirectusProvider')
	}

	return context
}
