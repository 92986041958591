'use client'

export default function Logout() {

	return (
		<img
			src="/logout.svg"
			alt="Logout"
			width={40}
			height={40}
			className="max-w-full max-h-full"
		/>
	)
}
