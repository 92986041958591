import moment from 'moment/moment'
import React, {useState} from 'react'
import Question from '../../directus/types/schema/Question'

type RelatedQuestionPropsType = {
	masterQuestion: Question
	question: Question
}

const RelatedQuestion = (
	{
		masterQuestion,
		question,
	}: RelatedQuestionPropsType,
) => {
	const [open, setOpen] = useState(false)
	return (
		<div className="bg-gray-50 rounded-md flex flex-col gap-2">
			<button
				className="bg-gray-200 rounded-md cursor-pointer block"
				onClick={() => setOpen(prevState => !prevState)}
			>
				<h4 className="font-bold p-1">
					#{masterQuestion.created_user.aid}-{question.aid} - {question.name} ({moment(question.created_date).format('DD.MM.YYYY')})
				</h4>
			</button>
			{open && (
				<>
					<div>
						<strong className="font-bold">Otázka:</strong><br />
						{question.question}
					</div>
					<div>
						<strong className="font-bold">Odpověď veterináře:</strong><br />
						{question.answer}
					</div>
				</>
			)}
		</div>
	)
}

export default RelatedQuestion
