import {AuthenticationData, AuthenticationStorage} from '@directus/sdk'

const LOCAL_STORAGE_KEY = 'directus-data'

class LocalStorage implements AuthenticationStorage { // TODO: rename and move
	get(): AuthenticationData | null {
		const storedAuthenticationData = localStorage.getItem(LOCAL_STORAGE_KEY)

		if (storedAuthenticationData !== null) {
			return JSON.parse(storedAuthenticationData)
		}

		return null
	}

	set(data: AuthenticationData | null): void {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
	}
}

export default LocalStorage
