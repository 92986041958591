import {useCallback, useState} from 'react'
import {DIRECTUS_HOST} from '../directus/constants'
import {useDirectus} from '../directus/DirectusContext'
import catchError from '../utils/catchError'

const LoginScreen = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [error, setError] = useState<null | string>(null)

	const {login} = useDirectus()

	const handleLogin = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
		setLoading(true)

		const [loginError] = await catchError(login(email, password))

		if (loginError) {
			setError(loginError.message)
		}

		setLoading(false)
	}, [login, email, password])

	if (loading) {
		return (
			<div>
				auth loading...
			</div>
		)
	}

	return (
		<section className="bg-gray-50">
			<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
				<a
					href="/"
					className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
				>
					<img
						className="h-8 mr-2"
						src={`${DIRECTUS_HOST}/assets/a83f3b7c-8191-47ba-9f26-fd5ac87f2d54`}
						alt="logo"
					/>
				</a>
				<div
					className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0"
				>
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
							Přihlásit se do služby
						</h1>
						<form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
							{error && (
								<div className="m-4 text-center text-danger">{error}</div>
							)}
							<div>
								<label
									htmlFor="email"
									className="block mb-2 text-sm font-medium text-gray-900"
								>
									Váš email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
									placeholder="••••••••@vetee.ai"
									value={email}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
								/>
							</div>
							<div>
								<label
									htmlFor="password"
									className="block mb-2 text-sm font-medium text-gray-900"
								>
									Heslo
								</label>
								<input
									type="password"
									name="password"
									id="password"
									placeholder="••••••••"
									className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
									value={password}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
								/>
							</div>
							<div className="flex items-center justify-between">
								<div className="flex items-start">
									<div className="flex items-center h-5">
										<input
											id="remember"
											aria-describedby="remember"
											type="checkbox"
											className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
										/>
									</div>
									<div className="ml-3 text-sm">
										<label
											htmlFor="remember"
											className="text-gray-500"
										>
											Zapamatovat si mě
										</label>
									</div>
								</div>
								<a
									href="mailto:info@vetee.ai"
									className="text-sm font-medium text-primary-500 hover:underline"
								>
									Zapomenuté heslo?
								</a>
							</div>
							<button
								type="submit"
								className="w-full text-white bg-primary-500 hover:bg-primary-300 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
							>
								Přihlásit se
							</button>
							<p className="text-sm font-light text-gray-500">
								Ještě nemáte účet?{' '}
								<a
									href="mailto:info@vetee.ai"
									className="font-medium text-primary-500 hover:underline"
								>
									Zaregistrujte se.
								</a>
							</p>
						</form>
					</div>
				</div>
			</div>
		</section>
	)
}

export default LoginScreen
