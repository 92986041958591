'use client'

export default function Attendance() {

	return (
		<img
			src="/attendance.svg"
			alt="Attendance"
			width={40}
			height={46}
			className="max-w-full max-h-full"
		/>
	)
}
