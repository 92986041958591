'use client'

export default function Questions() {

	return (
		<img
			src="/questions.svg"
			alt="Questions"
			width={40}
			height={32}
			className="max-w-full max-h-full"
		/>
	)
}
