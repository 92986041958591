'use client'

export default function Logo() {

	return (
		<img
			src="/icon.svg"
			alt="Logo"
			width={40}
			height={40}
			className="max-w-full max-h-full"
		/>
	)
}
