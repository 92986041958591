import React from 'react'
import {useDirectus} from '../../directus/DirectusContext'
import Attendance from '../icon/Attendance'
import Logo from '../icon/Logo'
import Logout from '../icon/Logout'
import Questions from '../icon/Questions'

export type SidebarPropsType = {
	screen: null | string
	setScreen: (screen: null | string) => void
}

const Sidebar = (
	{
		screen,
		setScreen,
	}: SidebarPropsType,
) => {
	const {logout} = useDirectus()

	return (
		<div
			className="md:sticky md:top-0 flex flex-row md:flex-col gap-4 w-full md:w-[64px] md:min-w-[64px] h-[64px] md:h-screen p-2 bg-gray-900 border-b-[1px] md:border-b-0 md:border-r-[1px] border-gray-100"
		>
			<button
				type="button"
				className="p-1 bg-primary-500 rounded"
				onClick={() => setScreen(null)}
			>
				<Logo/>
			</button>
			<div
				className="flex flex-row md:flex-col gap-2"
			>
				<button
					type="button"
					className={`p-2 bg-gray-800 hover:bg-gray-700 text-white rounded ${screen === 'questions' && 'border-[1px] border-white'}`}
					onClick={() => setScreen('questions')}
				>
					<Questions/>
				</button>
				<button
					type="button"
					className={`p-2 bg-gray-800 hover:bg-gray-700 text-white rounded ${screen === 'attendance' && 'border-[1px] border-white'}`}
					onClick={() => setScreen('attendance')}
				>
					<Attendance/>
				</button>
			</div>
			<div className="flex-grow"></div>
			<div>
				<button
					type="button"
					className="p-2 bg-gray-800 hover:bg-gray-700 text-white rounded"
					onClick={() => logout()}
				>
					<Logout/>
				</button>
			</div>
		</div>
	)
}

export default Sidebar
