class AsyncQueue {
	private tasks: (() => Promise<void>)[] = [];
	private isProcessing = false;

	enqueue(task: () => Promise<void>) {
		this.tasks.push(task);
		if (!this.isProcessing) {
			this.processQueue();
		}
	}

	private async processQueue() {
		this.isProcessing = true;
		while (this.tasks.length > 0) {
			const task = this.tasks.shift();
			if (task) {
				try {
					await task();
				} catch (error) {
					console.error("Task failed:", error);
				}
			}
		}
		this.isProcessing = false;
	}
}

export default AsyncQueue
