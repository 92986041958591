export type QuestionFormDataType = {
	name: null | string
	answer: null | string
	veterinarian_evaluation: null | number
	veterinarian_evaluation_reason: null | string
}

export default QuestionFormDataType

export const defaultQuestionFormData = {
	name: null,
	answer: null,
	veterinarian_evaluation: null,
	veterinarian_evaluation_reason: null,
}
