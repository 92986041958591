import moment from 'moment'
import React, {Dispatch, SetStateAction} from 'react'
import {DIRECTUS_HOST} from '../../directus/constants'
import {useDirectus} from '../../directus/DirectusContext'
import Question from '../../directus/types/schema/Question'

const statusToNameMap = {
	[`new`]: 'Nový dotaz (čeká na zpracování LLM)',
	[`pending`]: 'Dotaz ke zpracování',
	[`accepted`]: 'Dotaz přijatý veterinářem',
	[`answered`]: 'Zodpovězený dotaz',
	[`archived`]: 'Archivovaný dotaz',
}

type QuestionsListPropsType = {
	questions: Question[]
	setQuestionId: Dispatch<SetStateAction<null | string>>
}

const QuestionsList = (
	{
		questions,
		setQuestionId,
	}: QuestionsListPropsType,
) => {
	const {user, accessToken} = useDirectus()

	return (
		<div>
			<div className="flex flex-row justify-between align-middle mb-4 pb-4 border-b-[1px] border-b-gray-200">
				<h1 className="font-bold text-4xl">Položené dotazy</h1>
			</div>
			{questions.length === 0 && (
				<div>Žádné nové dotazy</div>
			)}
			{questions.length > 0 && (
				<ul
					className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
				>
					{questions.map((question, index) => {
						// ${question.veterinarian_user === null ? 'border-white' : (question.veterinarian_user?.id === user?.id ? (question.status === 'pending' ? 'border-warning' : 'border-danger') : 'border-info')}
						let borderColor = 'border-gray-300'

						if (['new', 'answered', 'archived'].includes(question.status)) {
							borderColor = 'border-success'
						} else if (moment(question.created_date).isBefore(moment().subtract(5, 'hours'))) {
							borderColor = 'border-danger'
						} else if (moment(question.created_date).isBefore(moment().subtract(1, 'hours'))) {
							borderColor = 'border-warning'
						}

						let borderStyle = 'border-dotted'

						if (
							question.status === 'pending' ||
							question.veterinarian_user?.id === user?.id
						) {
							borderStyle = ''
						} else if (question.veterinarian_user?.id !== user?.id) {
							borderStyle = 'border-dashed'
						}

						return (
							<li
								key={index}
								className={`m-2 p-4 flex flex-col gap-2 bg-white hover:bg-gray-50 rounded border-[1px] ${borderColor} ${borderStyle} cursor-pointer`}
								onClick={() => setQuestionId(question.id)}
							>
								<img
									src={`${DIRECTUS_HOST}/assets/${question.animal.avatar?.id}?access_token=${accessToken}&width=640&height=300`}
									alt={question.animal.name}
									width={640}
									height={300}
									className={`rounded max-w-full`}
								/>
								<div className="flex flex-row justify-between align-middle">
									<h3 className="font-bold text-lg">{question.animal.name}</h3>
									<em>Stáří: {moment(question.created_date).fromNow(true)}</em>
								</div>
								<div>
									<strong>Status:</strong>{' '}
									<span>{statusToNameMap[question.status] ?? '-'}</span>
								</div>
								<div>
									<strong>Veterinář:</strong>{' '}
									{question.veterinarian_user !== null ? (
										<span>
											{question.veterinarian_user?.first_name}{' '}
											{question.veterinarian_user?.last_name}{' '}
											({question.veterinarian_user?.email})
										</span>
									) : (
										<span>-</span>
									)}
								</div>
							</li>
						)
					})}
				</ul>
			)}
		</div>
	)
}

export default QuestionsList
