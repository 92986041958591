import moment from 'moment'
import {AttendanceType} from '../directus/types/schema/Attendance'

export type AttendanceFormDataType = {
	type: AttendanceType
	date_from: null | moment.Moment
	date_till: null | moment.Moment
}

export default AttendanceFormDataType

export const defaultAttendanceFormData: AttendanceFormDataType = {
	type: 'shift',
	date_from: null,
	date_till: null,
}
