import {authentication, createDirectus, DirectusUser, readMe, realtime, rest} from '@directus/sdk'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {DIRECTUS_HOST} from './constants'
import DirectusContext from './DirectusContext'
import LocalStorage from './LocalStorage'
import Schema from './types/Schema'

export type DirectusProviderProps = {
	children: React.ReactNode;
}

const DirectusProvider = (
	{
		children,
	}: DirectusProviderProps,
) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [version, setVersion] = useState<number>(0)
	const [user, setUser] = useState<DirectusUser<Schema> | null>(null)
	const [accessToken, setAccessToken] = useState<string | null>(null)

	const client = useMemo(() => {
		return createDirectus<Schema>(DIRECTUS_HOST) // TODO: DIRECTUS_HOST constant
			.with(authentication('cookie', {
				credentials: 'include',
				autoRefresh: true,
				storage: new LocalStorage(),
			}))
			.with(rest({
				credentials: 'include',
				onRequest: (options) => ({...options, cache: 'no-store'}),
			}))
			.with(realtime())
	}, [])

	const login = useCallback(async (email: string, password: string): Promise<void> => {
		await client.login(email, password)

		const user = await client.request(readMe()) as DirectusUser<Schema>
		const accessToken = await client.getToken()

		setUser(user)
		setAccessToken(accessToken)
		setVersion(prevState => ++prevState)
	}, [client])

	const logout = useCallback(async (): Promise<void> => {
		try {
			await client.logout()
		} catch (error) {
			console.error(error)
		}

		await client.setToken(null)

		setUser(null)
		setAccessToken(null)
		setVersion(prevState => ++prevState)
	}, [client])

	useEffect(() => {
		(async () => {
			const accessToken = await client.getToken()

			if (
				user === null &&
				accessToken !== null
			) {
				setUser(await client.request(readMe()) as DirectusUser<Schema>)
				setAccessToken(accessToken)
			}

			setLoading(false)
		})().then().catch()
	}, [version, user, client])

	if (loading) {
		return (
			<div>loading...</div>
		)
	}

	return (
		<DirectusContext.Provider
			value={{
				version,
				client,
				user,
				accessToken,
				login,
				logout,
			}}
		>
			{children}
		</DirectusContext.Provider>
	)
}

export default DirectusProvider
