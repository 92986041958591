'use client'

import {createItem, deleteItem, updateItem} from '@directus/sdk'
import moment from 'moment'
import React, {useCallback, useMemo} from 'react'
import {DIRECTUS_HOST} from '../../directus/constants'
import {useDirectus} from '../../directus/DirectusContext'
import Attendance from '../../directus/types/schema/Attendance'

type AttendanceCalendarPropsType = {
	attendances: Attendance[]
	week: moment.Moment
}

const AttendanceCalendar = (
	{
		attendances,
		week,
	}: AttendanceCalendarPropsType,
) => {
	const {user, accessToken, client} = useDirectus()

	const weekDays = useMemo(() => {
		const startOfWeek = week.clone().startOf('isoWeek')

		return Array.from({length: 7}, (_, i) => startOfWeek.clone().add(i, 'days'))
	}, [week])

	const getEventsForDay = useCallback((day: moment.Moment) => {
		return attendances
			.filter((attendance) => {
				const start = moment(attendance.date_from)
				const end = moment(attendance.date_till)

				return (
					start.isSame(day, 'day') ||
					(end.isBefore(day.endOf('day')) && end.isAfter(day.startOf('day')))
				)
			})
	}, [attendances])

	return (
		<div>
			<div className="grid grid-cols-8 border-t border-l">
				<div
					className="border-r"
				>
					<div className="h-8 bg-gray-200"></div>
					<div className="relative">
						{Array.from({length: 24}, (_, hour) => (
							<div key={hour} className="h-8 pr-2 border-b text-sm text-right leading-8">
								{`${String(hour).padStart(2, '0')}:00`}
							</div>
						))}
					</div>
				</div>
				{weekDays.map((day, dayIndex) => (
					<div
						key={dayIndex}
						className="border-r"
					>
						<div
							className="h-8 bg-gray-200 font-bold text-sm text-center leading-8"
						>
							{day.format('ddd D.M.')}
						</div>
						<div
							className="relative"
						>
							{Array.from({length: 24}, (_, hour) => (
								<div key={hour} className="h-8 border-b text-sm text-right leading-8"></div>
							))}
							{getEventsForDay(day).map((attendance) => {
								const startOfDay = day.clone().startOf('day');
								const endOfDay = day.clone().endOf('day');

								const dateFrom = moment(attendance.date_from)
								const dateTill = moment(attendance.date_till)

								const startHour = dateFrom.isBefore(startOfDay) ? 0 : dateFrom.diff(startOfDay, 'hours', true);
								const endHour = dateTill.isAfter(endOfDay) ? 24 : dateTill.diff(startOfDay, 'hours', true);

								const top = `${startHour * 2}rem`;
								const height = `${(endHour - startHour) * 2}rem`;

								return (
									<div
										key={attendance.id}
										className="absolute left-0 right-0 text-white rounded px-1 py-2 flex flex-col gap-2"
										style={{top, height, backgroundColor: attendance.user.color}}
									>
										{attendance.user.avatar && typeof attendance.user.avatar === 'object' && (
											<div className="flex justify-center">
												<img
													src={`${DIRECTUS_HOST}/assets/${attendance.user.avatar.id}?access_token=${accessToken}&width=128&height=128`}
													alt={`${attendance.user.first_name} ${attendance.user.last_name}`}
													width={64}
													height={64}
													className={`rounded-full max-w-full`}
												/>
											</div>
										)}
										<h4 className="text-center text-sm font-semibold">
											{attendance.user.first_name} {attendance.user.last_name}
										</h4>
										<div className="text-center text-xs font-light">
											{day.isSame(dateFrom, 'day') ? dateFrom.format('HH:mm') : dateFrom.format('D.M. HH:mm')} - {day.isSame(dateTill, 'day') ? dateTill.format('HH:mm') : dateTill.format('D.M. HH:mm')}
										</div>
										{user && user.id === attendance.user.id && (
												<button
													type="button"
													className="bg-danger hover:bg-gray-300 rounded-md p-1 text-white block"
													onClick={async () => {
														await client.request(updateItem(
															// @ts-ignore
															'attendances',
															attendance.id,
															{
																status: 'archived',
															}
														))
													}}
												>
													Smazat
												</button>
										)}
									</div>
								)
							})}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default AttendanceCalendar
