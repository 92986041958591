import moment from 'moment/moment'
import React, {useMemo, useState} from 'react'
import AttendanceCalendar from '../components/attendance/AttendanceCalendar'
import AttendanceForm from '../components/attendance/AttendanceForm'
import Attendance, {AttendanceType} from '../directus/types/schema/Attendance'
import AttendanceFormDataType, {defaultAttendanceFormData} from '../types/AttendanceFormDataType'

type AttendanceScreenPropsType = {
	attendances: Attendance[]
}

const AttendanceScreen = (
	{
		attendances,
	}: AttendanceScreenPropsType,
) => {
	const [week, setWeek] = useState<moment.Moment>(moment().startOf('isoWeek'))
	const [type, setType] = useState<AttendanceType>('shift')
	const [formOpen, setFormOpen] = useState<boolean>(false)
	const [formData, setFormData] = useState<AttendanceFormDataType>(defaultAttendanceFormData)

	const visibleAttendances = useMemo(() => {
		return attendances
			.filter(attendance => attendance.status === 'active' && attendance.type === type)
	}, [attendances, type])

	return (
		<div>
			<div
				className="flex flex-row justify-between align-middle mb-4 pb-4 border-b-[1px] border-b-gray-200"
			>
				<div
					className="flex flex-row gap-4"
				>
					<h1 className="font-bold text-4xl">Služby</h1>
					<button
						type="button"
						className="bg-primary-500 hover:bg-primary-300 border border-magenta-300 rounded-md p-2 text-white"
						onClick={() => setFormOpen(true)}
					>
						+ Přidat
					</button>
				</div>
				<div
					className="flex flex-row gap-4"
				>
					<button
						type="button"
						className="bg-white hover:bg-gray-300 border border-gray-300 rounded-md p-2 text-gray-500"
						onClick={() => setWeek(prevState => prevState.clone().subtract(1, 'week'))}
					>
						&lt; Předchozí týden
					</button>
					<button
						type="button"
						className="bg-white hover:bg-gray-300 border border-gray-300 rounded-md p-2 text-gray-500"
						onClick={() => setWeek(moment())}
					>
						Tento týden
					</button>
					<button
						type="button"
						className="bg-white hover:bg-gray-300 border border-gray-300 rounded-md p-2 text-gray-500"
						onClick={() => setWeek(prevState => prevState.clone().add(1, 'week'))}
					>
						Následující týden &gt;
					</button>
				</div>
				<select
					value={type}
					onChange={event => setType(event.target.value as 'shift' | 'vacation' | 'illness')}
					className="border border-gray-300 rounded-md p-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
				>
					<option value="shift">Směna</option>
					<option value="vacation">Dovolená</option>
					<option value="illness">Nemoc</option>
				</select>
			</div>
			{!formOpen && <AttendanceCalendar attendances={visibleAttendances} week={week}/>}
			{formOpen && (
				<AttendanceForm
					close={() => setFormOpen(false)}
					formData={formData}
					setFormData={setFormData}
				/>
			)}
		</div>
	)
}

export default AttendanceScreen
