import {createItem} from '@directus/sdk'
import moment from 'moment'
import React, {Dispatch, SetStateAction} from 'react'
import {useDirectus} from '../../directus/DirectusContext'
import {AttendanceType} from '../../directus/types/schema/Attendance'
import AttendanceFormDataType, {defaultAttendanceFormData} from '../../types/AttendanceFormDataType'

type AttendanceFormTypeProps = {
	close: () => void
	formData: AttendanceFormDataType
	setFormData: Dispatch<SetStateAction<AttendanceFormDataType>>
}

const AttendanceForm = (
	{
		close,
		formData,
		setFormData,
	}: AttendanceFormTypeProps,
) => {
	const {user, client} = useDirectus()

	return (
		<div
			className="flex flex-col gap-4"
		>
			<div>
				<label htmlFor="type">
					Typ:
				</label>
				<select
					id="type"
					value={formData.type}
					onChange={event => setFormData(prevState => ({
						...prevState,
						type: event.target.value as AttendanceType,
					}))}
					className="border border-gray-300 rounded-md p-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
				>
					<option value="shift">Směna</option>
					<option value="vacation">Dovolená</option>
					<option value="illness">Nemoc</option>
				</select>
			</div>
			<div>
				<label htmlFor="date_from">
					Začátek směny:
				</label>
				<input
					id="date_from"
					type="datetime-local"
					value={formData.date_from ? formData.date_from.format('YYYY-MM-DD HH:mm:ss') : undefined}
					onChange={event => setFormData(prevState => ({
						...prevState,
						date_from: moment(event.target.value),
					}))}
					className="border border-gray-300 rounded-md p-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
				/>
			</div>
			<div>
				<label htmlFor="date_till">
					Konec směny:
				</label>
				<input
					id="date_till"
					type="datetime-local"
					value={formData.date_till ? formData.date_till.format('YYYY-MM-DD HH:mm:ss') : undefined}
					onChange={event => setFormData(prevState => ({
						...prevState,
						date_till: moment(event.target.value),
					}))}
					className="border border-gray-300 rounded-md p-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
				/>
			</div>
			<div className="flex flex-row gap-4">
				<button
					type="button"
					className="bg-primary-500 hover:bg-primary-300 border border-magenta-300 rounded-md p-2 text-white"
					onClick={async () => {
						await client.request(createItem(
							// @ts-ignore
							'attendances',
							{
								...formData,
								status: 'active',
								// @ts-ignore
								user: user.id,
							},
							{
								fields: [
									'id',
								],
							},
						))

						setFormData(defaultAttendanceFormData)

						close()
					}}
				>
					Uložit
				</button>
				<button
					type="button"
					className="bg-danger border border-magenta-300 rounded-md p-2 text-white"
					onClick={() => {
						setFormData(defaultAttendanceFormData)

						close()
					}}
				>
					Zrušit
				</button>
			</div>
		</div>
	)
}

export default AttendanceForm
