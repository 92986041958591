import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './components/app/App'
import DirectusProvider from './directus/DirectusProvider'
import 'moment/locale/cs'

moment.locale('cs')

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
)

root.render(
	<React.StrictMode>
		<DirectusProvider>
			<App/>
		</DirectusProvider>
	</React.StrictMode>
)
